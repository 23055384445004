import React, { useState } from "react"

import Bulldozer from "../images/icons/icon-bulldozer-74x74.png"
import Tassels from "../images/icons/icon-tassels-70x70.png"
import Ruler from "../images/icons/icon-ruler-45x71.png"

import ServiceModal from "./modal"

import "./service.scss"


const Service = ({ project }) => {
  const [isOpen, setOpen] = useState(false)

  // const{ shortDescription, title, ico } = project

  const toggle = (event) => {
    event.preventDefault()
    setOpen(isOpen => !isOpen)
  }  
  
  return (
    <>
      <div onClick={toggle} className="service-card">
        <section className="service-icon">
          <img src={project?.icon} alt={project?.title} />
        </section>
        <h4>{project?.title}</h4>
        <p style={{ minHeight: 80 }}>{project?.shortDescription}</p>
      </div>

      <ServiceModal project={project} isOpen={isOpen} toggle={toggle} />
    </>
  )
}

export default Service
